export default {
	data() {
		return {
            isSelectAll: false,
            resultList: [],
            isResultReady: false
		}
	},
	methods: {
        toggleSelection(item, value) {
            item.isSelect = value;
		},
		toggleAllSelection(value) {
            this.isSelectAll = value;

            this.resultList.forEach((item) => {
                this.toggleSelection(item, value);
            });
		},
        getSelectedProducts() {
            return this.resultList
                .filter(item => item.isSelect)
                .map(item => item.sellerProductId);
        }
	}
}