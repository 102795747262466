<template>
    <div class="search">
        <div class="form">
            <div class="row">
                <div class="col col-6 col-md-4">
                    <div class="input-field">
                        <dropdown
                            v-model="form.width"
                            placeholder="ความกว้าง"
                            :classes="{ input: [ 'square' , 'input-option' ] }"
                            :options="option.width">
                        </dropdown>
                    </div>
                </div>
                <div class="col col-6 col-md-4">
                    <div class="input-field">
                        <dropdown
                            v-model="form.ratio"
                            placeholder="ซีรีย์"
                            :classes="{ input: [ 'square' , 'input-option' ] }"
                            :options="option.ratio">
                        </dropdown>
                    </div>
                </div>
                <div class="col col-6 col-md-4">
                    <div class="input-field">
                        <dropdown
                            v-model="form.diameter"
                            placeholder="ขอบล้อ"
                            :classes="{ input: [ 'square' , 'input-option' ] }"
                            :options="option.diameter">
                        </dropdown>
                    </div>
                </div>
                <div class="col col-6 col-md-4">
                    <div class="input-field">
                        <dropdown
                            v-model="form.brand"
                            placeholder="ยี่ห้อยาง"
                            :classes="{ input: [ 'square', 'input-option' ] }"
                            :options="option.brand">
                        </dropdown>
                    </div>
                </div>
                <div class="col col-6 col-md-4">
                    <div class="input-field">
                        <input
                            v-model="form.sku"
                            type="text"
                            placeholder="SKU"
                            class="square" />
                    </div>
                </div>
                <div class="col col-6 col-md-4">
                    <div class="input-field">
                        <dropdown
                            v-model="form.year"
                            placeholder="ปีผลิต"
                            :classes="{ input: [ 'square' , 'input-option' ] }"
                            :options="option.year">
                        </dropdown>
                    </div>
                </div>
                <div class="col col-6 col-md-4">
                    <div class="input-field">
                        <input
                            v-model="form.keyword"
                            type="text"
                            placeholder="ค้นหาโดยชื่อรุ่น"
                            class="square" />
                    </div>
                </div>
            </div>

            <div class="remark mt-1"><b-icon icon="info-circle-fill"></b-icon> กดเลือกยี่ห้อยาง หรือขอบล้อ หรือบางส่วนแล้วกดปุ่ม ต้นหา</div>

            <div class="mt-3 text-center">
                <button class="btn btn-main btn-search" @click="submitSearch()"><font-awesome-icon :icon="['fas', 'search']" class="icon" /> ค้นหา</button>
                <button class="btn btn-reset" @click="resetForm()">รีเซ็ต</button>
            </div>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import MasterService from '@/services/masterService';
import MixinDragScroll from '@/mixins/dragScroll';

export default {
    mixins: [ MixinDragScroll ],
	data() {
		return {
			form: this.initForm(),
			option: {
                width: [],
                ratio: [],
                diameter: [],
                brand: [],
                year: []
            }
		}
	},
	mounted() {
		this.initOptions();
	},
	methods: {
		async initOptions() {
            const resultTire = await MasterService.getAllTireSizeOption();
            const resultBrand = await MasterService.getProductBrand();

			this.option.width = Helper.setupOption(resultTire.data.width);
            this.option.ratio = Helper.setupOption(resultTire.data.ratio);
            this.option.diameter = Helper.setupOption(resultTire.data.diameter);
            this.option.brand = Helper.setupOption(resultBrand.data);
            this.option.year = Helper.initStockYearOption();
		},
		initForm() {
			return {
				width: '',
				ratio: '',
				diameter: '',
                brand: '',
                sku: '',
                year: '',
                keyword: ''
			};
		},
		submitSearch() {
            this.$emit('submitSearch', this.form);
		},
        resetForm() {
			this.form = this.initForm();

            this.$emit('submitSearch', this.form);
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/SettingSearch.scss';
</style>