<template>
    <div>
        <seller-search-product-setting @submitSearch="submitSearch($event)"></seller-search-product-setting>

        <template v-if="isResultReady">
            <div class="operation">
                <span>สินค้าที่เลือกไว้ (<span class="text-danger">{{ resultList.filter(x => x.isSelect).length }}</span>)</span>

                <select v-model="allowPrepaid">
                    <option value="" disabled>-- โปรดเลือก --</option>
                    <option value="1">เปิด-มัดจำ</option>
                    <option value="0">ไม่เปิด-มัดจำ</option>
                </select>

                <button class="btn btn-sm btn-warning" @click="assignPrepaidProduct()">ตั้งค่าการมัดจำ</button>
            </div>

            <div class="remark mt-2 mb-2"><b-icon icon="info-circle-fill"></b-icon> กดเลือกยี่ห้อยาง หรือขอบล้อ หรือบางส่วน แล้วเลือกกลุ่มด้านบน เพื่อตั้งค่าการมัดจำ</div>

            <div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
                <table>
                    <thead>
                        <tr>
                            <th class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!isSelectAll"
                                        @click="toggleAllSelection(true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="isSelectAll"
                                        @click="toggleAllSelection(false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </th>
                            <th>ยี่ห้อ/รุ่นสินค้า</th>
                            <th>ขนาด</th>
                            <th>ปียาง</th>
                            <th class="text-center">ตั้งค่ามัดจำ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in resultList" :key="index">
                            <td class="text-center">
                                <button class="btn btn-sm btn-outline-secondary"
                                    v-if="!item.isSelect"
                                    @click="toggleSelection(item, true)">
                                </button>
                                <button class="btn btn-sm btn-primary"
                                    v-if="item.isSelect"
                                    @click="toggleSelection(item, false)">
                                    <b-icon icon="check"></b-icon>
                                </button>
                            </td>
                            <td>{{ item.brand }}<br />{{ item.model }}</td>
                            <td>{{ item.size }}</td>
                            <td>{{ item.year }}</td>
                            <td class="text-center">
                                {{ item.isAllowPrepaid ? 'เปิด-มัดจำ' : 'ไม่เปิด-มัดจำ' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </div>
</template>

<script>
import SellerService from '@/services/sellerService';
import MixinDragScroll from '@/mixins/dragScroll';
import MixinSettingProduct from '@/components/seller/mixins/settingProduct';
import SellerSearchProductSetting from '@/components/seller/shop/SearchProductSetting';

export default {
    mixins: [ MixinDragScroll, MixinSettingProduct ],
    components: {
        SellerSearchProductSetting
    },
	async mounted() {
        this.submitSearch();
	},
	data() {
		return {
            allowPrepaid: ''
		}
	},
	methods: {
		async submitSearch(param) {
            this.isSelectAll = false;

			let loader = this.$modalLoader.render();
            const result = await SellerService.getProductForSetting(param);
            loader.hide();

            this.isResultReady = true;
            this.resultList = result.data.map(x => ({ ...x, isSelect: false }));
		},
        async assignPrepaidProduct() {
            const param = {
                assignedProducts: this.getSelectedProducts(),
                allowPrepaid: this.allowPrepaid
            };

            let loader = this.$modalLoader.render();
            await SellerService.assignPrepaidProduct(param);
            loader.hide();

            // Reload
            this.allowPrepaid = '';
            this.submitSearch();
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/SettingSearch.scss';
@import '@/components/seller/style/Setting.scss';
</style>