<template>
	<div class="setting-prepaid">
		<h2>ตั้งค่าการมัดจำ</h2>

		<seller-installation-prepaid></seller-installation-prepaid>
	</div>
</template>

<script>
import SellerInstallationPrepaid from '@/components/seller/shop/InstallationPrepaid';

export default {
	components: {
        SellerInstallationPrepaid
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.setting-prepaid {
	h2 {
		font-size: 24px;
		font-family: 'Open Sans', 'Kanit';
		font-weight: bold;
		color: #333;
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px dashed #ccc;

		@media only screen and (max-width: $screenSmall) {
			font-size: 18px;
		}
	}
}
</style>